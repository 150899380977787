import React from "react";
import { Card, FormLayout, TextField, Checkbox, Divider } from "@shopify/polaris";

export default function ChannelNotification({
  enableChannelNotification,
  setEnableChannelNotification,
  channelDiscordID,
  setChannelDiscordID,
  enableChannelOrderPaid,
  setEnableChannelOrderPaid,
  channelOrderPaidMessage,
  setChannelOrderPaidMessage,
  enableChannelOrderCancelled,
  setEnableChannelOrderCancelled,
  channelOrderCancelledMessage,
  setChannelOrderCancelledMessage,
  enableChannelCustomerCreate,
  setEnableChannelCustomerCreate,
  channelCustomerCreateMessage,
  setChannelCustomerCreateMessage,
}) {
  return (
    <Card title="Channel Notification Settings">
      <FormLayout>
        <Checkbox
          label="Enable Channel Notifications"
          checked={enableChannelNotification}
          onChange={() => setEnableChannelNotification(!enableChannelNotification)}
        />
        <TextField
          label="Discord Server Channel ID"
          value={channelDiscordID}
          onChange={setChannelDiscordID}
          disabled={!enableChannelNotification}
        />
        <Divider />
        <Checkbox
          label="Enable Channel Orders Paid Notifications"
          checked={enableChannelOrderPaid}
          onChange={() => setEnableChannelOrderPaid(!enableChannelOrderPaid)}
        />
        <TextField
          label="Channel Order Paid Notification Message(Order Number ##orderNumber##, Total: ##totalPrice##, Customer: ##customerName## View Order: ##orderUrl##)"
          value={channelOrderPaidMessage}
          onChange={setChannelOrderPaidMessage}
          multiline={3}
          disabled={!enableChannelOrderPaid}
        />
        <Divider />
        <Checkbox
          label="Enable Channel Orders Cancelled Notifications"
          checked={enableChannelOrderCancelled}
          onChange={() => setEnableChannelOrderCancelled(!enableChannelOrderCancelled)}
        />
        <TextField
          label="Channel Order Cancelled Notification Message (Order Number ##orderNumber##, Customer: ##customerName## View Order: ##orderUrl##)"
          value={channelOrderCancelledMessage}
          onChange={setChannelOrderCancelledMessage}
          multiline={3}
          disabled={!enableChannelOrderCancelled}
        />
        <Divider />
        <Checkbox
          label="Enable Channel Customer Create Notifications"
          checked={enableChannelCustomerCreate}
          onChange={() => setEnableChannelCustomerCreate(!enableChannelCustomerCreate)}
        />
        <TextField
          label="Channel Customer Create Notification Message (name: ##customerName##, email: ##customerEmail##)"
          value={channelCustomerCreateMessage}
          onChange={setChannelCustomerCreateMessage}
          multiline={3}
          disabled={!enableChannelCustomerCreate}
        />
      </FormLayout>
    </Card>
  );
}
