import React, { useState, useEffect } from "react";
import { Page, Layout, Card, FormLayout, TextField, Checkbox, Frame, Divider, Spinner, Toast, Tabs } from "@shopify/polaris";
import ChannelNotification from "./ChannelNotification"; // Child Component

export default function Notification() {
  const [storeDomain] = useState(new URL(window.location).searchParams.get("shop"));
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [enableNotification, setEnableNotification] = useState(false);
  const [adminDiscordID, setAdminDiscordID] = useState("");
  const [enableOrderPaid, setEnableOrderPaid] = useState(false);
  const [orderPaidMessage, setOrderPaidMessage] = useState("New order paid: Order ##orderNumber##, Total: ##totalPrice##, Customer: ##customerName##\nView Order: ##orderUrl##");
  const [enableOrderCancelled, setEnableOrderCancelled] = useState(false);
  const [orderCancelledMessage, setOrderCancelledMessage] = useState("Order ##orderNumber## has been cancelled by ##customerName##\nView Order: ##orderUrl##");
  const [enableCustomerCreate, setEnableCustomerCreate] = useState(false);
  const [customerCreateMessage, setCustomerCreateMessage] = useState("New customer created name: ##customerName##, email: ##customerEmail##");

  const [enableChannelNotification, setEnableChannelNotification] = useState(false);
  const [channelDiscordID, setChannelDiscordID] = useState("");
  const [enableChannelOrderPaid, setEnableChannelOrderPaid] = useState(false);
  const [channelOrderPaidMessage, setChannelOrderPaidMessage] = useState("New channel order paid: Order ##orderNumber##, Total: ##totalPrice##, Customer: ##customerName##\nView Order: ##orderUrl##");
  const [enableChannelOrderCancelled, setEnableChannelOrderCancelled] = useState(false);
  const [channelOrderCancelledMessage, setChannelOrderCancelledMessage] = useState("Channel Order ##orderNumber## has been cancelled by ##customerName##\nView Order: ##orderUrl##");
  const [enableChannelCustomerCreate, setEnableChannelCustomerCreate] = useState(false);
  const [channelCustomerCreateMessage, setChannelCustomerCreateMessage] = useState("New customer created name: ##customerName##, email: ##customerEmail##");

  const toastMarkup = active ? (
    <Toast content="Saved Settings Successfully" onDismiss={() => setActive(false)} />
  ) : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-shopify-shop-domain': storeDomain
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data) {
            const dataStore = data.custom.discord_notifications;
            setEnableNotification(dataStore.enableNotification);
            setAdminDiscordID(dataStore.adminDiscordID);
            setEnableOrderPaid(dataStore.enableOrderPaid);
            setOrderPaidMessage(dataStore.orderPaidMessage);
            setEnableOrderCancelled(dataStore.enableOrderCancelled);
            setOrderCancelledMessage(dataStore.orderCancelledMessage);
            setEnableCustomerCreate(dataStore.enableCustomerCreate);
            setCustomerCreateMessage(dataStore.customerCreateMessage);

            setEnableChannelNotification(dataStore.enableChannelNotification);
            setChannelDiscordID(dataStore.channelDiscordID);
            setEnableChannelOrderPaid(dataStore.enableChannelOrderPaid);
            setChannelOrderPaidMessage(dataStore.channelOrderPaidMessage);
            setEnableChannelOrderCancelled(dataStore.enableChannelOrderCancelled);
            setChannelOrderCancelledMessage(dataStore.channelOrderCancelledMessage);
            setEnableChannelCustomerCreate(dataStore.enableChannelCustomerCreate);
            setChannelCustomerCreateMessage(dataStore.channelCustomerCreateMessage);
          }
        } else {
          console.error(response.status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [storeDomain]);

  // Save both admin and channel settings
  const handleSubmit = async () => {
    setLoading(true);

    const discord_notifications = {
      enableNotification,
      adminDiscordID,
      enableOrderPaid,
      orderPaidMessage,
      enableOrderCancelled,
      orderCancelledMessage,
      enableCustomerCreate,
      customerCreateMessage,
      enableChannelNotification,
      channelDiscordID,
      enableChannelOrderPaid,
      channelOrderPaidMessage,
      enableChannelOrderCancelled,
      channelOrderCancelledMessage,
      enableChannelCustomerCreate,
      channelCustomerCreateMessage,
    };

    try {
      const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'x-shopify-shop-domain': storeDomain
        },
        body: JSON.stringify({ discord_notifications }),
      });

      if (response.ok) {
        console.log("Settings saved:", response.status);
        setActive(true);
      } else {
        console.error("Failed to save settings:", response.status);
      }
    } catch (error) {
      console.error("Error during save:", error);
    } finally {
      setLoading(false);
    }
  };

  const tabs = [
    { id: "admin-notifications", content: "Admin Notifications" },
    { id: "channel-notifications", content: "Channel Notifications" }
  ];

  return (
    <div style={{ height: "250px" }}>
      <Frame>
        <Page
          title="Discord Notifications"
          primaryAction={{
            content: loading ? <Spinner size="small" color="teal" /> : "Save Settings",
            onAction: handleSubmit
          }}
        >
          <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
          <Layout>
            {selectedTab === 0 && (
              <Layout.Section>
                <Card title="Admin Notification Settings">
                  <FormLayout>
                    <Checkbox
                      label="Enable Discord Notifications"
                      checked={enableNotification}
                      onChange={() => setEnableNotification(!enableNotification)}
                    />
                    <TextField
                      label="Admin Discord ID"
                      value={adminDiscordID}
                      onChange={setAdminDiscordID}
                      disabled={!enableNotification}
                    />
                    <Divider />
                    <Checkbox
                      label="Enable Orders Paid Notifications"
                      checked={enableOrderPaid}
                      onChange={() => setEnableOrderPaid(!enableOrderPaid)}
                    />
                    <TextField
                      label="Order Paid Notification Message"
                      value={orderPaidMessage}
                      onChange={setOrderPaidMessage}
                      multiline={3}
                      disabled={!enableOrderPaid}
                    />
                    <Divider />
                    <Checkbox
                      label="Enable Orders Cancelled Notifications"
                      checked={enableOrderCancelled}
                      onChange={() => setEnableOrderCancelled(!enableOrderCancelled)}
                    />
                    <TextField
                      label="Order Cancelled Notification Message"
                      value={orderCancelledMessage}
                      onChange={setOrderCancelledMessage}
                      multiline={3}
                      disabled={!enableOrderCancelled}
                    />
                    <Divider />
                    <Checkbox
                      label="Enable Customer Create Notifications"
                      checked={enableCustomerCreate}
                      onChange={() => setEnableCustomerCreate(!enableCustomerCreate)}
                      hidden
                    />
                    <TextField
                      label="Customer Create Notification Message"
                      value={customerCreateMessage}
                      onChange={setCustomerCreateMessage}
                      multiline={3}
                      disabled={!enableCustomerCreate}
                      hidden
                    />
                  </FormLayout>
                </Card>
              </Layout.Section>
            )}
            {selectedTab === 1 && (
              <Layout.Section>
                <ChannelNotification
                  enableChannelNotification={enableChannelNotification}
                  setEnableChannelNotification={setEnableChannelNotification}
                  channelDiscordID={channelDiscordID}
                  setChannelDiscordID={setChannelDiscordID}
                  enableChannelOrderPaid={enableChannelOrderPaid}
                  setEnableChannelOrderPaid={setEnableChannelOrderPaid}
                  channelOrderPaidMessage={channelOrderPaidMessage}
                  setChannelOrderPaidMessage={setChannelOrderPaidMessage}
                  enableChannelOrderCancelled={enableChannelOrderCancelled}
                  setEnableChannelOrderCancelled={setEnableChannelOrderCancelled}
                  channelOrderCancelledMessage={channelOrderCancelledMessage}
                  setChannelOrderCancelledMessage={setChannelOrderCancelledMessage}
                  enableChannelCustomerCreate={enableChannelCustomerCreate}
                  setEnableChannelCustomerCreate={setEnableChannelCustomerCreate}
                  channelCustomerCreateMessage={channelCustomerCreateMessage}
                  setChannelCustomerCreateMessage={setChannelCustomerCreateMessage}
                />
              </Layout.Section>
            )}
          </Layout>
          {toastMarkup}
        </Page>
      </Frame>
    </div>
  );
}
